<template>
	<div class="list" v-if="modelValue">
		<div class="filter">
			<p class="filter-company" v-show="current_user.app_admin==1" @click="companyShow=!companyShow">
				<span class="company-name">{{companyList.find(one=>one.dept_id==filter.dept_id)?.corp_name}}</span>
				<span class="arrow icon-2"></span>
			</p>
			<div class="search-box">
				<span class="search-icon icon-5"></span>
				<input @compositionstart="handleCompositionStart"
					   @compositionend="handleCompositionEnd" ref="input" class="input" type="text" v-model="filter.value" placeholder="搜索用户姓名" @input="input"/>
				<span class="clear-icon icon-10" v-show="filter.value.length>0" @click="clear"></span>
			</div>
		</div>
		<div class="list-box" @scroll="scroll">
			<div class="list-user" v-for="item in list">
				<img class="avatar" :src="item.avatar" alt="" v-if="item.avatar" />
				<span class="avatar" v-else>{{item.name.substr(-2)}}</span>
				<div class="role">
					<p class="role-name">
						<span class="name">{{item.name}}</span>
						<span class="app-admin" :style="{borderColor:app_admin_list.find(one=>one.value==item.app_admin)?.border_color,color:app_admin_list.find(one=>one.value==item.app_admin)?.color}">{{app_admin_list.find(one=>one.value==item.app_admin)?.name}}</span>
					</p>
					<p class="role-mobile">
								<span class="mobile">
									联系方式：{{item.mobile}}
								</span>
					</p>
				</div>
				<div class="auth" v-if="item.app_admin!=1&&current_user.app_admin!=3">
					<span class="switch icon-7" @click="opt(item,'switch')" v-show="current_user.user_id!==item.user_id"></span>
					<span class="delete icon-8" v-show="current_user.user_id!==item.user_id" @click="opt(item,'delete')"></span>
				</div>
			</div>
			<no-data v-show="list.length==0"></no-data>
		</div>
		<span class="add icon-11" @click="addUserShow=true" v-show="current_user.app_admin!=3"></span>
		<popup v-model="companyShow" title="企业列表">
			<template #content>
				<div class="company-list">
					<p class="list-one" :class="{selected:item.dept_id==filter.dept_id}" v-for="item in companyList" @click="clickItem(item)">
						<span class="corp-name">{{item.corp_name}}</span>
					</p>
					<no-data v-show="companyList.length==0"></no-data>
				</div>
			</template>
		</popup>
		<popup v-model="addUserShow" height="100vh" :round="false" :showTop="false">
			<template #content>
				<div class="add-user">
					<div class="content">
						<div class="col">
							<div class="col-name">用户姓名</div>
							<div class="col-content">
								<new-input v-model="add_user.name" placeholder="请输入用户姓名"></new-input>
							</div>
						</div>
						<div class="col">
							<div class="col-name">用户电话</div>
							<div class="col-content">
								<new-input type="telephone" v-model="add_user.mobile" placeholder="请输入用户电话"></new-input>
							</div>
						</div>
					</div>
					<div class="opt">
						<span class="btn default" @click="cancel">取消</span>
						<span class="btn default" @click="saveAndNext" style="margin-left: .1rem">保存并添加下一个</span>
						<span class="btn" @click="save" style="margin-left: .1rem">保存</span>
					</div>
				</div>
			</template>
		</popup>
	</div>
</template>

<script>
    import {defineComponent, onMounted, toRefs, reactive,computed,watch} from "vue"
	import {useRouter} from "vue-router"
	import {useStore} from "vuex"
	import {Dialog,Toast} from "vant"
	import api from "@/api/methods"
	import * as dd from 'dingtalk-jsapi';
	import formValid from '@/components/input/formValid';
	import myPublic from '@/utils/public';
    export default defineComponent({
        name: "user",
        props: {
			modelValue: {
				type: Boolean,
				default: false
			},
			dept_id: {
				type: String,
				default: ''
			}
        },
		mixins:[formValid],
        setup(props, {emit}) {
        	const router = useRouter();
        	const store = useStore();
            const state = reactive({
				app_admin_list:[
					{
						name:'超级管理员',
						value:1,
						color:'#ffa61a',
						border_color:'#ffa61a',
					},{
						name:'管理员',
						value:2,
						color:'#ffa61a',
						border_color:'#ffa61a',
					},{
						name:'企业成员',
						value:3,
						color:'#999',
						border_color:'#999',
					},
				],
				companyShow:false,
				companyList:[],
				list:[],
				page:1,
				page_size:15,
				total:0,
				next_time:Date.now(),
				filter:{
					value:'',
					dept_id:'',
				},
				add_user:{
					name:'',
					mobile:'',
					admin:3
				},
				addUserShow:false,
				isPc:dd.pc
            });
			const current_user = computed(()=>{
				return store.state.user.current_user
			});
			watch(()=>props.modelValue,(val)=>{
				if(val){
					if(props.dept_id){
						state.filter.dept_id = props.dept_id;
					}
					init();
				}
			},{immediate:true,deep:true});
            function getCompanyList(page=1) {
				let p ={
					page:page,
					page_size:999
				};
				state.page=page;
				return api.company.getCompanyList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								item.admin = item.admin_user?item.admin_user[0].name:'--';
							});
							if(!state.filter.dept_id){
								if(current_user.value.dept_id){
									state.filter.dept_id = current_user.value.dept_id;
								}else{
									state.filter.dept_id = res.data.list[0]?.dept_id;
								}
							}
							state.companyList = res.data.list;
						}
					}
					return true;
				})
            }
            function getList(page=1) {
				let p ={
					page:page,
					page_size:state.page_size
				};
				state.page=page;
				if(state.filter.dept_id){
					p.dept_id = state.filter.dept_id;
				}
				if(state.filter.value){
					p.name = state.filter.value;
				}
				api.user.getUserList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							if(page == 1){
								state.list = res.data.list;
							}else{
								state.list.push(...res.data.list);
							}
							state.total = res.data.total;
						}
					}
				})
            }
			function scroll(e) {
				if (e.target.clientHeight + e.target.scrollTop + 1 > e.target.scrollHeight) {
					if (Date.now() - state.next_time>500&&Math.ceil(state.total/state.page_size)>state.page) {
						state.next_time = Date.now();
						state.page++;
						getList(state.page);
					}
				}
			}
			function back(){
				router.go(-1);
			}
			function clickItem(item){
				if(state.filter.dept_id != item.dept_id){
					state.filter.dept_id = item.dept_id;
				}
				state.companyShow = false;
				getList();
			}
			function clear(){
            	state.filter.value = '';
				getList();
			}
			function setUserForMember(item) {
				let p = {
					user_id:item.user_id,
					dept_id:item.dept_id
				};
				api.user.setUserForMember(p).then(async (res)=>{
					if(res.code==0){
						Toast.success({
							message: "设置成功!",
						});
						if(item.user_id==current_user.value.user_id){
							await myPublic.getUserDetail();
							await router.replace({path:'/home'});
						}
						getList();
					}
				})
			}
			function setUserForAdmin(item) {
				let p = {
					user_id:item.user_id,
					dept_id:item.dept_id
				};
				api.user.setUserForAdmin(p).then((res)=>{
					if(res.code==0){
						Toast.success({
							message: "设置成功!",
						});
						if(item.user_id==current_user.value.user_id){
							myPublic.getUserDetail()
						}
						getList();
					}
				})
			}
			function deleteUser(item) {
				let p = {
					user_id:item.user_id
				};
				api.user.deleteUser(p).then((res)=>{
					if(res.code==0){
						Toast.success({
							message: "删除成功!",
						})
						getList();
					}
				})
			}
			function opt(item,type){
				if(type=='switch'){
					Dialog.confirm({
						title: '操作提醒',
						message:`确定将【${item.app_admin==2?'管理员':'企业成员'}】${item.name}的角色切换成【${item.app_admin==2?'企业成员':'管理员'}】？`
					}).then(() => {
							if(item.app_admin==2){
								setUserForMember(item);
							}else if(item.app_admin==3){
								setUserForAdmin(item);
							}
					}).catch(() => {
							// on cancel
						});
				}else if(type=='delete'){
					Dialog.confirm({
						title: '操作提醒',
						message:`确定删除【${item.app_admin==2?'管理员':'企业成员'}】${item.name}？`
					}).then(() => {
						deleteUser(item)
					}).catch(() => {
							// on cancel
					});
				}
			}
			async function init(){
				await getCompanyList();
				getList();
			}
            return {
                ...toRefs(state),
				current_user,
				back,
				getList,
				clickItem,
				clear,
				scroll,
				opt
            }
        },
		data(){
			return {
				isComposing:false
			}
		},
		methods:{
			handleCompositionStart() {
				this.isComposing = true;
			},
			handleCompositionEnd(event) {
				this.isComposing = false;
				this.input(event);
			},
			input(e){
				if(this.isComposing) return;
				this.getList();
			},
			cancel(){
				this.add_user.name = '';
				this.add_user.mobile = '';
				this.addUserShow = false;
			},
			saveAndNext() {
				this.save(false);
			},
			save(bool=true) {
				this.add_user.dept_id = this.filter.dept_id;
				let p = this.add_user;
				if(!this.add_user.name){
					return this.$toast({
						message: "请填写用户姓名！",
						type:'fail'
					});
				}
				if(!this.validMobile(this.add_user.mobile)){
					return;
				}
				api.user.addUser(p).then((res)=>{
					if(res.code==0){
						this.$toast({
							message: "添加成功!",
							type:'success'
						});
						this.getList();
						if(!bool){
							this.add_user.name = '';
							this.add_user.mobile = '';
						}else{
							this.addUserShow = false;
						}
					}
				})
			}
		}
    })
</script>

<style scoped lang="less">
	.list{
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		border-top: .03rem solid #eef2f5;
		overflow: hidden;
		.filter{
			position: relative;
			width: calc(100% - .4rem);
			display: flex;
			align-items: center;
			flex-shrink: 0;
			padding: .1rem .2rem;
			background-color: #fff;
			.filter-company{
				max-width: 2rem;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-right: .1rem;
				cursor: pointer;
			}
			.search-box{
				flex: 1;
				position: relative;
				display: inline-flex;
				align-items: center;
				overflow: hidden;
				.search-icon{
					position: absolute;
					left: .08rem;
					font-size: .16rem;
					color: #c4ccd9;
				}
				.input{
					flex: 1;
					height: .34rem;
					border-radius: 2em;
					background-color: #eef2f5;
					padding:0 .25rem 0 .3rem;
					overflow: hidden;
				}
				.clear-icon{
					position: absolute;
					right: .08rem;
					font-size: .16rem;
					color: #c4ccd9;
				}
			}
			.btn{
				display: inline-flex;
				align-items: center;
				height: .36rem;
				background-color: #2ca0f7;
				color: #fff;
				border-radius: 2em;
				padding:0 .1rem;
				margin-left: .1rem;
				cursor: pointer;
				.icon{
					font-size: .12rem;
					margin-right: .05rem;
				}
				&:active{
					opacity: .8;
				}
			}
		}
		.list-box{
			flex: 1;
			background-color: #fff;
			padding: 0 .2rem;
			overflow-y: auto;
			.list-corp{
				padding: .1rem 0;
				border-bottom: 1px solid #efefef;
				.corp-name{
					height: .24rem;
					display: flex;
					align-items: center;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					font-size: .16rem;
					font-weight: bolder;
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.list-user{
				padding: .1rem 0;
				display: flex;
				border-bottom: 1px solid #efefef;
				.avatar{
					width: .5rem;
					height: .5rem;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					background-color: #2aa0f7;
					border-radius: 50%;
					border: 2px solid #fff;
					font-size: .12rem;
				}
				.role{
					flex: 1;
					display: flex;
					flex-direction: column;
					margin:0 .1rem;
					overflow: hidden;
					.role-name{
						flex: 1;
						display: flex;
						align-items: center;
						overflow: hidden;
						.name{
							min-width: 0;
							color: #333;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							font-size: .16rem;
							font-weight: bolder;
						}
						.app-admin{
							flex-shrink: 0;
							border-radius: 1em;
							border: 1px solid #fff440;
							color: #fff440;
							padding:.02rem .08rem;
							margin-left: .05rem;
							font-size: .12rem;
						}
					}
					.role-mobile{
						flex: 1;
						display: flex;
						align-items: center;
						overflow: hidden;
						.mobile{
							flex: 1;
							color: #999;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
				.auth{
					display: inline-flex;
					align-items: center;
					.switch{
						color: #2aa0f7;
						margin: 0 .1rem;
						cursor: pointer;
					}
					.delete{
						color: #F56C6C;
						margin:0 .1rem;
						cursor: pointer;
					}
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.list-device{
				padding: .1rem 0;
				display: flex;
				border-bottom: 1px solid #efefef;
				.device{
					flex: 1;
					overflow: hidden;
					.device-name{
						min-width: 0;
						color: #333;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						font-size: .16rem;
						font-weight: bolder;
					}
					.device-id{
						color: #999;
						display: flex;
						align-items: center;
						height: .3rem;
						overflow: hidden;
					}
				}
				.auth{
					display: inline-flex;
					align-items: center;
					.switch{
						color: #2aa0f7;
						margin: 0 .1rem;
						cursor: pointer;
					}
					.delete{
						color: #F56C6C;
						margin:0 .1rem;
						cursor: pointer;
					}
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.col{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: .24rem;
				color: #999;
			}
		}
		.add{
			position: absolute;
			bottom: .5rem;
			right: .3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			width: .5rem;
			height: .5rem;
			border-radius: 50%;
			color: #fff;
			background-color: #2aa0f7;
			cursor: pointer;
			box-shadow: 0 0 15px rgba(0,0,0,.15);
			&:active{
				opacity: .8;
			}
		}
	}
	.add-user{
		height: 100%;
		display: flex;
		flex-direction: column;
		.content{
			flex: 1;
			overflow-y: auto;
			padding: .1rem .15rem;
			.col{
				padding-top: .05rem;
				padding-bottom: .15rem;
				.col-name{

				}
				.col-content{
					padding-top: .05rem;
				}
			}
		}
		.opt{
			flex-shrink: 0;
			display: flex;
			align-items: center;
			min-height: .5rem;
			border-top: 1px solid #efefef;
			padding: 0 .15rem;;
			.btn{
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: .4rem;
				border-radius: .04rem;
				background-color: #2aa0f7;
				color: #fff;
				font-size: .12rem;
				border: 1px solid transparent;
				cursor: pointer;
				&:active{
					opacity: .8;
				}
			}
			.default{
				background-color: #fff;
				color: #2aa0f7;
				border: 1px solid #2aa0f7;
			}
		}
	}
	.company-list{
		height: 100%;
		padding: 0 .25rem;
		overflow-y: auto;
		.list-one{
			display: flex;
			align-items: center;
			height: .4rem;
			border-bottom: 1px solid #efefef;
			cursor: pointer;
			.corp-name{
				flex: 1;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.selected{
			.corp-name{
				color: #2aa0f7;
			}
		}
	}
</style>
